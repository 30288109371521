/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiAccordion: Components<Omit<Theme, 'components'>>['MuiAccordion'] = {
  styleOverrides: {
    root: {
      borderRadius: '0 !important',
    },
  },
};
