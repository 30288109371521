/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { typography } from '../typography';

export const MuiTableContainer: Components<Omit<Theme, 'components'>>['MuiTableContainer'] = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
};

export const MuiTableRow: Components<Omit<Theme, 'components'>>['MuiTableRow'] = {
  styleOverrides: {
    root: {
      '&.MuiTableRow-hover:hover': {
        '--th-table-background': 'var(--color-surf-container-low)',
        cursor: 'pointer',
      },
      '&.matTableRowSelected .MuiTableCell-root': {
        borderColor: 'blue',
        borderTop: 'solid 1px blue',
        '&:first-child': {
          borderLeft: 'solid 1px blue',
        },
        '&:last-child': {
          borderRight: 'solid 1px blue',
        },
      },
    },
  },
};

export const MuiTableCell: Components<Omit<Theme, 'components'>>['MuiTableCell'] = {
  styleOverrides: {
    root: {
      color: 'var(--th-table-row-color, var(--color-on-surf-variant))',
      borderColor: 'var(--color-outline-variant)',
      borderTop: 'solid var(--th-table-row-border-size, 1px) var(--th-table-row-border-color, transparent)',
      borderBottom: 'solid var(--th-table-row-border-size, 1px) var(--th-table-row-border-color, transparent)',
      '&:first-child': {
        borderLeft: 'solid var(--th-table-row-border-size, 1px) var(--th-table-row-border-color, transparent)',
      },
      '&:last-child': {
        borderRight: 'solid var(--th-table-row-border-size, 1px) var(--th-table-row-border-color, transparent)',
      },
      ...typography.body3,
    },
    head: {
      ...typography.labelMedium,
      backgroundColor: 'var(--th-table-background, transparent)',
      whiteSpace: 'nowrap',
      '&.MuiTableCell-sizeSmall': {
        paddingLeft: '0.5rem',
      },
      '&.MuiTableCell-sizeMedium': {
        padding: '0.5rem 1rem',
      },
      '& .MuiTableSortLabel-root': {
        paddingLeft: '0.125rem',
        marginLeft: '-0.125rem',
        width: '100%',
        justifyContent: 'space-between',
      },
      '& .MuiTableSortLabel-root:hover': {
        background: 'var(--color-surf-container-highest)',
        borderRadius: '0.25rem',
        color: 'var(--color-on-surf)',
      },
    },
    body: {
      backgroundColor: 'var(--th-table-background, transparent)',
      '&.MuiTableCell-sizeSmall': {
        paddingLeft: '0.5rem',
      },
      '&.MuiTableCell-sizeMedium': {
        padding: '0.75rem 1rem',
      },
    },
  },
};
