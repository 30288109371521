import { IOnlyTimeDisplayOptions } from '@th-common/interfaces/date-time.interface';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import parseZone from './parseZone.plugin';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(parseZone);
dayjs.updateLocale('en', {
  relativeTime: {
    // relative time format strings, keep %s %d as the same
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: 'a month',
    MM: '%dm',
    y: 'a year',
    yy: '%dy',
  },
});

export namespace DateTimeUtils {
  export const last3Weeks = dayjs().subtract(3, 'weeks').endOf('day');
  export const longTimeAgoDate = dayjs.parseZone('2017-01-01T00:00:00.00Z');
  export const lastWeekDate = dayjs().subtract(7, 'days');

  export const ceil = (date: Dayjs, valueInSeconds: number): Dayjs => {
    const valueInMilliseconds = valueInSeconds * 1000;
    return dayjs(Math.ceil(date.valueOf() / valueInMilliseconds) * valueInMilliseconds);
  };

  export const onlyDateRequest = (date: Dayjs): string => {
    return date.format('YYYY-MM-DD');
  };

  export const noTimeZoneDateTime = (date: Dayjs | string, format = 'YYYY-MM-DDTHH:mm:ss'): string => {
    const dayjsDate = typeof date === 'string' ? dayjs.parseZone(date) : date;

    return dayjsDate.tz(dayjs.tz.guess()).format(format);
  };

  export const onlyDateKey = (date: Dayjs | string | null | undefined): string => {
    if (!date) {
      return '';
    }

    const dayjsDate = typeof date === 'string' ? dayjs.parseZone(date) : date;

    return dayjsDate.format('MMDDYYYY');
  };
  export const onlyDateDisplay = (date: Dayjs | string | null | undefined): string => {
    if (!date) {
      return '';
    }

    const dayjsDate = typeof date === 'string' ? dayjs.parseZone(date) : date;

    return dayjsDate.format('MM/DD/YYYY');
  };

  export const onlyTimeDisplay = (date: Dayjs | string | null | undefined, options?: IOnlyTimeDisplayOptions): string => {
    if (!date) {
      return '';
    }

    const hoursSegment = options && options.use12Hour ? 'hh' : 'HH';
    const amPmSegment = options && options.use12Hour ? 'A' : '';

    const dayjsDate = typeof date === 'string' ? dayjs.parseZone(date) : date;

    return dayjsDate.format(`${hoursSegment}:mm${options && options.hideSeconds ? '' : ':ss'}${` ${amPmSegment}`}`);
  };

  export const onlyYearDisplay = (date: Dayjs | string | null | undefined): string => {
    if (!date) {
      return '';
    }

    const dayjsDate = typeof date === 'string' ? dayjs.parseZone(date) : date;

    return dayjsDate.format('YYYY');
  };

  export const monthShort = (date: Dayjs | string | null | undefined): string => {
    if (!date) {
      return '';
    }

    const dayjsDate = typeof date === 'string' ? dayjs.parseZone(date) : date;

    return dayjsDate.format('MMM');
  };

  export const timestampDisplay = (date: string, options?: {
    use12Hour?: boolean;
  }): string => {
    const hoursSegment = options && options.use12Hour ? 'hh' : 'HH';
    const amPmSegment = options && options.use12Hour ? 'A' : '';
    return dayjs.parseZone(date).format(`MM/DD/YYYY | ${hoursSegment}:mm:ss${` ${amPmSegment}`}`);
  };

  export const fileTimestampDisplay = (date: string, options?: {
    use12Hour?: boolean;
  }): string => {
    const hoursSegment = options && options.use12Hour ? 'hh' : 'HH';
    const amPmSegment = options && options.use12Hour ? 'A' : '';
    return dayjs.parseZone(date).format(`MM-DD-YYYY-${hoursSegment}-mm-ss-${amPmSegment}`);
  };

  export const durationDisplay = (
    durationValue: number,
    options?: {
      isInSeconds?: boolean;
    },
  ): string => {
    const durationValueInMs = options?.isInSeconds ? durationValue * 1000 : durationValue;
    const days = Number(dayjs.duration(durationValueInMs).format('D'));
    const hoursFormat = dayjs.duration(durationValueInMs).format('HH:mm:ss');
    return days > 0 ? `${days}.${hoursFormat}` : hoursFormat;
  };

  export const getDifference = (date1: string, date2: string): number => {
    return dayjs.parseZone(date1).diff(dayjs.parseZone(date2));
  };

  export const isNextDay = (date1: string, date2: string): boolean => {
    return dayjs.parseZone(date1).isBefore(dayjs.parseZone(date2), 'day');
  };
}
